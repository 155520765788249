/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import { debounce } from 'lodash';
import { Tab, Tabs } from 'react-bootstrap';
import { Grid, Search } from 'react-bootstrap-icons';
import Spinner from 'react-bootstrap/Spinner';
import { useHistory } from 'react-router-dom';

import AllGames from 'src/conponents/Home/AllGames';
import { DEFAULT_CONFIGS } from 'src/constants';
import useSearchQueryParams from 'src/hooks/useSearchQueryParams';

import './index.scss';
import useTranslate from 'src/hooks/useTranslate';
import { useLanguageContext } from 'src/context/languageContext';
// import { setHeaderLocale } from 'src/helpers/request';

const providers = [
  {
    id: '229d7bc1480583d7e9ec499c',
    name: 'PP',
  },
  {
    id: '229d7bc1480583d7e9ec499a',
    name: 'PG',
  },
];

function HomeContainer() {
  const { i18n } = useLanguageContext();

  const [searchTerm, setSearchTerm] = useState('');
  const [originalData, setOriginalData] = useState([]);
  const [gamesData, setGamesData] = useState([]);

  // const groupCode = useSearchQueryParams(
  //   'groupCode',
  //   DEFAULT_CONFIGS.groupCode,
  // );

  const oc = useSearchQueryParams('oc', DEFAULT_CONFIGS.brandCode);
  const t = useSearchQueryParams('t', DEFAULT_CONFIGS.playerToken);

  const l = useSearchQueryParams('l', DEFAULT_CONFIGS.language);

  const values = useSearchQueryParams();

  const [sortBy, setSortBy] = useState(values.get('sortBy'));
  const handleSearchGame = (e) => {
    setSearchTerm(e.target.value);
  };
  const history = useHistory();

  const translate = useTranslate();

  const menuItems = useMemo(() => {
    const results = [
      {
        eventKey: 'all',
        label: translate('common.text.allGames', 'All Games'),
      },
    ];

    if (oc === 'cldemo1') {
      results.push(
        ...providers.map((x) => ({
          eventKey: x.id,
          label: x.name,
        })),
      );
    } else {
      results.push(
        ...[
          {
            eventKey: 'playCount',
            label: translate('common.text.popular', 'Popular'),
          },
          {
            eventKey: 'created',
            label: translate('common.text.latest', 'Latest'),
          },
        ],
      );
    }

    return results;
  }, [oc, translate]);

  useEffect(() => {
    if (searchTerm !== '') {
      setGamesData(
        originalData.filter((item) =>
          item.name.toLowerCase().includes(searchTerm.toLowerCase()),
        ),
      );
    } else {
      setGamesData(originalData);
    }
  }, [searchTerm]);

  const debouncedResults = React.useMemo(
    () => debounce(handleSearchGame, 300),
    [],
  );

  useEffect(() => () => {
    debouncedResults.cancel();
  });

  const fetchData = async (params) => {
    setLoading(false);

    // const data = await listGames({
    //   ...params,
    //   playerToken,
    //   groupCode,
    //   brandCode,
    // });
    const path = params.providerId
      ? '/api/v1/lobby-available-games'
      : '/api/v1/available-games';
    const response = await fetch(`${path}?${new URLSearchParams(params)}`);
    const data = await response.json();

    if (data) {
      const games = data.data?.filter((x) => x.status === 'ACTIVE');
      setGamesData(games);
      setOriginalData(games);
    } else {
      setGamesData([]);
      setOriginalData([]);
    }
  };

  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('all');

  useEffect(() => {
    switch (sortBy) {
      case 'playCount':
        setFilter('playCount');
        break;
      case 'created':
        setFilter('created');
        break;
      default:
        if (sortBy?.length > 0 && providers.some((x) => x.id === sortBy)) {
          setFilter(sortBy);
          break;
        }
        setFilter('all');
        break;
    }

    const params = {};
    if (sortBy) {
      if (['created', 'playCount'].includes(sortBy)) {
        params.sortBy = sortBy;
      } else {
        params.providerId = sortBy;
      }
    }

    if (i18n?.locale) {
      // setHeaderLocale(i18n.locale);
      fetchData({
        ...params,
        oc,
        t,
        l,
      });
    } else {
      setLoading(false);
    }
  }, [sortBy, i18n?.locale]);

  const handleFilter = (key) => {
    if (key === 'all') {
      values.delete('sortBy');
      setSortBy('');
    } else {
      setSortBy(key);
      values.set('sortBy', key);
    }
    const url = `${window.location.pathname}?${values.toString()}`;
    history.push(url);
  };

  return (
    <div className={`home-page__wrapper ${(loading && '--loading') || ''}`}>
      {!loading && (
        <>
          <div className="search-container">
            <div className="search-icon">
              <Search />
            </div>
            <input
              type="text"
              className="form-control"
              placeholder={translate('common.text.search', 'Search')}
              onChange={debouncedResults}
            />
          </div>
          <div className="home-page__tabs-container">
            <Tabs
              className="mb-xxl-5 mb-4"
              variant="pills"
              activeKey={filter}
              onSelect={(e) => handleFilter(e)}
            >
              {menuItems.map((item) => (
                <Tab
                  eventKey={item.eventKey}
                  className="home-page__tabs-item"
                  title={
                    <div className="home-page__tabs-item__title">
                      <Grid size={14} />
                      <span>{item.label}</span>
                    </div>
                  }
                />
              ))}
            </Tabs>
            <AllGames games={gamesData} />
          </div>
        </>
      )}
      {loading && (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
    </div>
  );
}

export default HomeContainer;
